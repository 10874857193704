import React from 'react';
import { useInView } from 'react-intersection-observer';
import Head from 'next/head';
import { AditionTagPlacement } from '@vgn-medien-holding/vgn-fe-components';
import { AditionTagWithFallback } from '@vgn-medien-holding/vgn-fe-components';
import { Divider } from '@vgn-medien-holding/vgn-react-components';
import { getCookie } from 'cookies-next';
import type { GetStaticPaths, GetStaticProps } from 'next';
import { NEWSLETTER_ACTIVE } from 'tvm-config';
import { AditionInitialization } from '@components/atoms/Adition/AditionInitialization';
import { ArticleDate } from '@components/atoms/ArticleDate/ArticleDate';
import { EntitySectionTitle } from '@components/atoms/EntitySectionTitle/EntitySectionTitle';
import { LinkProps } from '@components/atoms/Link/Link';
import { Meta } from '@components/atoms/MetaTags/Meta';
import { Oewa } from '@components/atoms/Oewa/Oewa';
import { Title } from '@components/atoms/Title';
import { ArticleInfos } from '@components/molecules/ArticleInfos/ArticleInfos';
import { ImageCard } from '@components/molecules/ImageCard/ImageCard';
import { SocialButtons } from '@components/molecules/SocialButtons/SocialButtons';
import { ArticleTeaserCard } from '@components/organisms/ArticleTeaserCard/ArticleTeaserCard';
import { AuthorBoxBottom } from '@components/organisms/AuthorBoxBottom/AuthorBoxBottom';
import { Breadcrumbs } from '@components/organisms/Breadcrumbs/Breadcrumbs';
import * as ContentElements from '@components/organisms/ContentElements/ContentElements';
import { Newsletter } from '@components/organisms/Newsletter/Newsletter';
import { Article as ArticleLayout } from '@components/templates/layouts/Article';
import { createDate } from '@utils/dateHelpers';
import { GetBySlugFullDocument, Newsletter as NewsletterType } from '@src/lib/graphql/generated';
import { decodeOptions } from '@src/utils/urlHelpers';
import { adPositions } from '@lib/adition/adPositions';
import { GetGlobalsDocument } from '@lib/graphql/generated';
import { fetchExtraArticleData } from '@lib/graphql/helpers/fetchExtraArticleData';
import { createUrqlClient } from '@lib/graphql/urql';
import { useTagManager } from '@lib/hooks/useTagManager';
import { handleRedirect } from '@lib/redirects/redirect';

function Article({ data, extraData = null, newsletter }) {
  const {
    content,
    published_at,
    created_at,
    updated_at,
    cover_image,
    headline,
    authors,
    hide_authors,
    hide_authors_bottom,
    teaser,
    ressort,
    similar_articles,
    metadata,
    oewa,
  } = data;
  data.article = true;

  const breadcrumbsPath: LinkProps[] = [
    { href: '/', title: 'TV-MEDIA.AT' },
    { href: '/magazin', title: 'MAGAZIN' },
    { href: `/${ressort?.slug}`, title: ressort?.title.toUpperCase() },
  ];

  useTagManager({
    event: 'metadata',
    loginStatus: 'nicht eingeloggt',
    seitentyp: 'Artikel',
    titel: headline,
    assetID: oewa?.id,
    aktualisierungsdatum: data?.updated_at,
    erstellungsdatum: data?.created_at,
    oewaSeitenkontingent: oewa?.page_key || ressort?.slug === 'gewinnspiele' ? 'Games' : 'RedCont',
    oewaSubkontingent:
      oewa?.category_key || ressort?.slug === 'gewinnspiele' ? 'UnterhaltungGames' : 'KulturUndFreizeit',
    oewaProfilingkontingent: oewa?.profile_key || ressort?.slug === 'gewinnspiele' ? 'Gewinnspiele' : 'FilmUndKino',
    cookieAlert: getCookie('OptanonConsent'),
  });

  return (
    <>
      <Oewa
        pageKey={oewa?.page_key || ressort?.slug === 'gewinnspiele' ? 'Games' : 'RedCont'}
        categoryKey={oewa?.category_key || ressort?.slug === 'gewinnspiele' ? 'UnterhaltungGames' : 'KulturUndFreizeit'}
        profileKey={oewa?.profile_key || ressort?.slug === 'gewinnspiele' ? 'Gewinnspiele' : 'FilmUndKino'}
      />
      <AditionInitialization tags={adPositions} data={data} />
      <Head>{Meta({ ...metadata, open_graph_image: cover_image?.url })}</Head>

      <div className={'pt-10 text-gray-600 lg:grid lg:grid-cols-[1fr,auto,1fr]'}>
        <div className="relative hidden w-full justify-end pr-4 lg:flex">
          <AditionTagPlacement
            tag={adPositions[14]}
            classProps={{ root: 'sticky top-28 mb-4 h-[600px] hidden 3xl:block' }}
          />
        </div>
        <main className="relative mx-auto w-full min-w-0 max-w-[1090px]">
          <div className="grid place-items-center">
            <AditionTagWithFallback tag={adPositions[9]} fallback={adPositions[28]} breakpoint={'lg'} />
          </div>
          <div
            className={
              'mt-4 items-center justify-between border-gray-300 px-6 py-4 md:px-20 lg:mx-0 lg:flex lg:border-y lg:px-0'
            }
          >
            <Breadcrumbs breadcrumbs={breadcrumbsPath} />
            <div className="pt-2 lg:pt-0">
              {created_at ? (
                <ArticleDate
                  createdDate={createDate(published_at ? published_at : created_at)}
                  updatedDate={createDate(updated_at ? updated_at : created_at)}
                />
              ) : (
                ''
              )}
            </div>
          </div>

          <section className="relative mt-8 px-5 md:mt-25 md:px-20 lg:px-32">
            <Title level={1} classProps={{ heading: 'font-medium' }}>
              {headline}
            </Title>
            <div className={'prose my-9 md:prose-lg'} dangerouslySetInnerHTML={{ __html: teaser }} />
            <SocialButtons classProps={{ root: 'py-6 md:py-0 md:pb-6' }} />
            <Divider classProps={{ root: 'border-t-px border-gray-300 mb-5 lg:mb-10' }} />
            <ArticleInfos article={data} />
          </section>
          {cover_image?.url && (
            <div className={'mx-4 my-9 rounded-xl bg-white shadow-card-sm sm:mx-8 lg:mx-0'}>
              <ImageCard
                imageSrc={cover_image.url}
                imageAlt={cover_image.alt}
                priority
                width={2560}
                height={1440}
                imageMode="cover"
                classProps={{
                  ImageCard: 'rounded-lg',
                  Image: 'rounded-t-lg w-full',
                  ImageText: 'text-sm',
                  root: 'rounded-lg',
                }}
                sizes="(min-width: 1020px) 1280px, 100vw"
                description={cover_image.caption}
                copyright={cover_image.copyright}
              />
            </div>
          )}
          <section className="w-full px-4 pb-20 sm:px-8 lg:px-0">
            <div className={'w-full'}>
              <ArticleBody content={content} extraData={extraData} />
            </div>
            {similar_articles?.length > 0 && (
              <section className="content-element-padding mt-16">
                <EntitySectionTitle heading={false} classProps={{ root: 'mt-0' }}>
                  Ähnliche Artikel
                </EntitySectionTitle>
                <div className="tvm-scrollbar w-full min-w-0 overflow-x-scroll pb-8">
                  <div className="grid h-full grid-flow-col gap-3">
                    {similar_articles.map((article, index) => (
                      <div className="h-full min-h-[28rem] w-60 sm:w-72 md:w-96" key={index}>
                        <ArticleTeaserCard
                          article={article}
                          hideCategory
                          classProps={{
                            root: 'flex-none w-full h-full min-w-0 min-h-[18rem]',
                            ImageContainer: 'w-full h-full px-6 pt-6',
                            Image: 'relative w-full h-full rounded-lg overflow-hidden aspect-teaser lg:aspect-video',
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            )}
            {!hide_authors && !hide_authors_bottom && <AuthorBoxBottom authors={authors} />}
          </section>
        </main>
        <div className="relative hidden w-full justify-start pl-4 lg:flex">
          <AditionTagPlacement
            tag={adPositions[15]}
            classProps={{ root: 'sticky top-28 mb-4 h-[600px] hidden 3xl:block' }}
          />
        </div>
      </div>
      {NEWSLETTER_ACTIVE && newsletter?.id && (
        <Newsletter newsletter={newsletter} hasBackground classProps={{ root: 'my-15' }} />
      )}
    </>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const { ssrQuery } = createUrqlClient();

  let data;
  let extraData;
  let newsletter: NewsletterType = { id: null };

  const options = decodeOptions(params?.slug);

  try {
    const { data: globalData } = await ssrQuery({
      query: GetGlobalsDocument,
      variables: {},
    });

    try {
      newsletter = globalData != null && globalData['globals'] !== null ? globalData['globals']['newsletter'] : '';
    } catch (err) {
      console.error(err);
      newsletter = null;
    }

    const { data: entryData } = await ssrQuery({
      query: GetBySlugFullDocument,
      variables: {
        portal: 'tvmedia',
        slug: `${params.categoryOrPage}/${options.slug}`,
      },
      authToken: options?.params?.token,
    });
    data = entryData?.findBySlug;
  } catch (err) {
    console.log(err);
    return { notFound: true };
  }

  if (!data) return { notFound: true };

  // Handle redirect result
  if (data?.redirect_type || data?.metadata?.redirect) {
    return handleRedirect(
      data?.redirect_type,
      data?.redirect_target != null ? data?.redirect_target : data?.metadata?.redirect,
    );
  }

  if (data?.__typename === 'Article') {
    extraData = await fetchExtraArticleData(data);
  }

  return {
    revalidate: 60 * 60 * 24 * 2, // 2 days
    props: {
      data,
      extraData,
      newsletter,
      // urqlState: ssrCache.extractData(),
    },
  };
};

function ArticleElement({ children, index }) {
  const { ref: inViewRef, inView } = useInView({
    rootMargin: `${3000 / index}px 0px`,
    triggerOnce: true,
  });

  if (index < 20) return children;

  return (
    <div className="min-h-4" ref={inViewRef}>
      {inView ? children : null}
    </div>
  );
}

export function ArticleBody({ content, extraData = [] }) {
  const toc = content?.find((e) => e.__typename === 'ContentElementTableOfContents')?.list;

  const tocFlattened = [];
  if (toc) {
    const flattenElement = (el) => {
      if (el.children) {
        el.children.forEach((e) => {
          flattenElement(e);
        });
        tocFlattened.push(...el.children.map((f) => f.anchor));
      }
    };

    toc.forEach((e) => {
      tocFlattened.push(e.anchor);
      flattenElement(e);
    });
  }
  let tocCount = -1;

  let ad_count = 0;
  let textElement_count = 0;

  return (
    <div className="mt-16 space-y-16">
      {content?.map((element, idx) => {
        if (element.__typename == 'ContentElementText') textElement_count++;
        if (element.headline) tocCount++;
        return (
          <div className="scroll-mt-32" key={idx} id={tocFlattened?.[tocCount]}>
            <div className="scroll-mt-32" id={element.anchor}>
              <ArticleElement index={idx}>
                {element.headline && (
                  <Title level={element.headline_level} classProps={{ heading: 'pb-4 content-element-padding' }}>
                    {element.headline}
                  </Title>
                )}
                {ContentElements[element.__typename] && ContentElements[element.__typename](element, extraData)}
                {
                  /*FIXME: remove when ads are placed in the cms; places an ad between 2 contentelementtext objects*/
                  //switch ads for mobile and don't show more than 4 ads total
                  (idx == 0 ||
                    (textElement_count >= 3 &&
                      element.__typename == 'ContentElementText' &&
                      idx + 1 != content.length &&
                      content[idx + 1].__typename == 'ContentElementText' &&
                      ad_count < 3)) && (
                    <div className="grid place-items-center">
                      <AditionTagWithFallback
                        tag={adPositions[8]}
                        tagCount={ad_count}
                        fallback={adPositions[29 + ad_count++]}
                        breakpoint={'lg'}
                        classProps={{ root: 'm-auto mt-16' }}
                      />
                    </div>
                  )
                }
              </ArticleElement>
            </div>
          </div>
        );
      })}
    </div>
  );
}

Article.PageLayout = ArticleLayout;

export default Article;
